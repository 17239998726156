<template>
  <div :class="{
    'm-choose-red': true,
    'f-flex': true,
    'f-flex-jcc': true,
  }">
    <span class="m-choose-t"></span>三字組合
  </div>
  <div v-if="loading" class="loading"></div>
  <div class="f-flex">
    <div class="g-w50"  style="background：#ccc; margin:0 10px; border-radius: 10px;">
      <div class="f-flex f-flex-wrap" v-if="initial.result.length > 0">
        <div class="g-w10 p1" v-for="item in initial.result" :key="item.num">
          <span :class="{ 'kx-number': true, 'kx-current': !item.checked }" @click="item.checked = !item.checked">{{
            item.num }}</span>
        </div>
      </div>
      <div class="f-flex f-flex-wrap" style="position: relative; min-height: 489px" v-else>
        <div style="
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 489px;
            border-radius: 10px;
            background: rgba(0, 0, 0, 0.1);
          ">
          <span style="
              top: 50%;
              position: absolute;
              left: 50%;
              transform: translate(-50%, -50%);
              font-size: 22px;
            ">請生成號碼</span>
        </div>
      </div>
    </div>
    <div class="g-w50">
      <div class="kt-l">
        合尾數&nbsp;&nbsp;<input class="ant-input" style="width: 100px" type="number"
          v-model="initial.heweishu" />&nbsp;&nbsp;
        <label v-for="(item, index) in ['单', '双', '大', '小']" :key="index"><input name="heweishu" type="radio"
            :value="item" @change="heweishuChangeEvent" />{{ item }}</label>
      </div>
      <!-- <div class="kt-l">
        <div class="f-flex">
          <div
            class="g-w20"
            v-for="(item, index) in ['组三转直', '组六转直', '连号', '不连号']"
            :key="index"
          >
            <label
              ><input name="positioning" type="radio" :value="item" />{{
                item
              }}</label
            >
          </div>
        </div>
        <div class="f-flex">
          <div class="g-w25"><XtxCheckbox>合單</XtxCheckbox></div>
          <div class="g-w25"><XtxCheckbox>合双</XtxCheckbox></div>
          <div class="g-w25"><XtxCheckbox>合大</XtxCheckbox></div>
          <div class="g-w25"><XtxCheckbox>合小</XtxCheckbox></div>
        </div>
        <div class="f-flex">
          <div class="g-w25"><XtxCheckbox>0</XtxCheckbox></div>
          <div class="g-w25"><XtxCheckbox>1</XtxCheckbox></div>
          <div class="g-w25"><XtxCheckbox>2</XtxCheckbox></div>
          <div class="g-w25"><XtxCheckbox>3</XtxCheckbox></div>
        </div>
        <div class="f-flex">
          <div class="g-w25"><XtxCheckbox>4</XtxCheckbox></div>
          <div class="g-w25"><XtxCheckbox>5</XtxCheckbox></div>
          <div class="g-w25"><XtxCheckbox>6</XtxCheckbox></div>
          <div class="g-w25"><XtxCheckbox>7</XtxCheckbox></div>
        </div>
        <div class="f-flex">
          <div class="g-w25"><XtxCheckbox>8</XtxCheckbox></div>
          <div class="g-w25"><XtxCheckbox>9</XtxCheckbox></div>
          <div class="g-w25"><XtxCheckbox>全选</XtxCheckbox></div>
          <div class="g-w25"></div>
        </div>
      </div> -->
      <div class="kt-l f-flex">
        <div class="g-w50">
          和數值&nbsp;&nbsp;<input class="ant-input" style="width: 100px" type="number" v-model="initial.heshuzhi" />
        </div>
        <div class="g-w50">
          膽&nbsp;&nbsp;<input class="ant-input" style="width: 100px" type="number" v-model="initial.danma" />
        </div>
      </div>
      <div class="kt-l f-flex f-flex-wrap">
        <div class="g-w25 f-mt10" v-for="(item, index) in initial.chk01" :key="index">
          <XtxCheckbox v-model="item.checked">{{ item.text }}</XtxCheckbox>
        </div>
      </div>
      <div class="kt-l">
        <div class="f-flex f-flex-wrap">
          <div class="g-w15">二次和尾</div>
          <div class="g-w17" v-for="item in initial.chk02" :key="item.text">
            <XtxCheckbox v-model="item.checked">{{ item.text }}</XtxCheckbox>
          </div>
        </div>
        <div class="f-flex f-flex-wrap f-mt10">
          <div class="g-w15">二次差尾</div>
          <div class="g-w17" v-for="item in initial.chk03" :key="item.text">
            <XtxCheckbox v-model="item.checked">{{ item.text }}</XtxCheckbox>
          </div>
        </div>
      </div>
      <div class="kt-l f-flex f-flex-wrap f-mt10 f-w-b">
        <div class="g-w50 f-mt10">
          <span class="kx-t">單註最低</span>{{ initial.filter ? initial.filter.minMoney : 0 }}
        </div>
        <div class="g-w50 f-mt10">
          <span class="kx-t">單註最高</span>{{ initial.filter ? initial.filter.maxMoney : 0 }}
        </div>
        <div class="g-w50 f-mt10">
          <span class="kx-t">订单笔数</span>{{
            filter(initial.result, (itx) => {
              return itx.checked == true;
            }).length
          }}
        </div>
        <div class="g-w50 f-mt10">
          <span class="kx-t">总金额</span>{{
            filter(initial.result, (itx) => {
              return itx.checked == true;
            }).length * initial.amount
          }}
        </div>
      </div>
      <div class="kt-l f-tac">
        單註金額&nbsp;&nbsp;<input class="ant-input" style="width: 100px" type="number" v-model="initial.amount" />
        <button class="f-mlr10 btn-blue" @click="generateResultEvent">
          生成號碼
        </button>
        <button :class="{
          'btn-blue': store.state.game.config.status == 1,
          'btn-blue-disabled': store.state.game.config.status != 1,
          'f-mlr10': true,
        }" @click="emitConfirmBetEvent">
          註單送出
        </button>
        <button class="f-mlr10 btn-yellow" @click="emitClearBetEvent">
          清除
        </button>
      </div>
    </div>

  </div>

  <!--確認订單提交弹窗 -->
  <LhcBetConfirm @on-confirm-success="emitConfirmSuccessEvent" @on-confirm-cancel="emitConfirmCancelEvent"
    ref="confirmBet" :title="initial.title" :filter="initial.filter" />
</template>

<script>
import LhcBetConfirm from "@/views/liuhecai/components/lhc-bet-confirm.vue";
import { ref, watch, reactive, onMounted, nextTick, computed } from "vue";
import { useStore } from "vuex";
import filter from "lodash.filter";
import map from "lodash.map";
import { combination, isContinuation } from "@/utils/tools";
import endsWith from "lodash.endswith";
import isUndefined from "lodash.isundefined";
import find from "lodash.find";
import Message from "@/components/library/Message";
export default {
  name: "KuaiXuanPan",
  components: {
    LhcBetConfirm,
  },
  setup(props) {
    const loading = ref(false);
    const store = useStore();
    //弹窗組件
    const confirmBet = ref(null);
    //下註提交项
    let postBet = reactive({
      gameCode: store.state.app.currentGame.code,
      betType: 2008,
      betTypeName: "三字組合",
      contents: [],
    });
    onMounted(() => {
      nextTick(() => {
        requestGameOddsAndFilterEvent();
      });
    });

    let initial = reactive({
      number: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      chk01: [
        { text: "合单", checked: false },
        { text: "合双", checked: false },
        { text: "合大", checked: false },
        { text: "合小", checked: false },
        { text: "和值大", checked: false },
        { text: "和值小", checked: false },
        { text: "组三", checked: false },
        { text: "组六", checked: false },
        { text: "全奇", checked: false },
        { text: "全偶", checked: false },
        { text: "两奇一偶", checked: false },
        { text: "两偶一奇", checked: false },
        { text: "全大", checked: false },
        { text: "全小", checked: false },
        { text: "两大一小", checked: false },
        { text: "两小一大", checked: false },
        { text: "含质数号码", checked: false },
        { text: "不含质数号码", checked: false },
        { text: "单点号码", checked: false },
        { text: "双点号码", checked: false },
        { text: "三连号", checked: false },
        { text: "半连号", checked: false },
        { text: "不连号", checked: false },
      ],
      chk02: [
        { text: "0", checked: false },
        { text: "2", checked: false },
        { text: "4", checked: false },
        { text: "6", checked: false },
        { text: "8", checked: false },
      ],
      chk03: [
        { text: "0", checked: false },
        { text: "2", checked: false },
        { text: "4", checked: false },
        { text: "6", checked: false },
        { text: "8", checked: false },
      ],
      numbers: [],
      zhishu: [1, 2, 3, 5, 7],
      heshu: [0, 4, 6, 8, 9],
      result: [], //快捷方式计算出结果
      amount: null, //下注单注
      betType: 2008,
      filter: {},
      //快捷
      heweishu: "", //合尾数
      heshuzhi: "", //和数值
      danma: "", //胆码
    });
    /**
     *确认下单
     */
    const emitConfirmBetEvent = () => {
      if (store.state.game.config.status != 1) {
        return;
      }
      let checked = find(initial.result, (item) => {
        return item.checked == true;
      });
      if (isUndefined(checked)) {
        Message({ text: "请生成投註项" });
        return;
      }
      if (!initial.amount || initial.amount <= 0) {
        Message({ text: "请输入單註金額" });
        return;
      }
      initial.result.forEach((elment) => {
        if (elment && elment.checked) {
          postBet.contents.push({
            typeId: initial.betType,
            odds: find(initial.numbers, (item) => {
              return item.ball == elment.num;
            }).odds,
            tabType: 0,
            betMoney: Number(initial.amount),
            nums: elment.num,
          });
        }
      });
      confirmBet.value.open(postBet);
    };
    /**
     * 生成结果
     */
    const generateResultEvent = () => {
      loading.value = true;
      initial.result = [];
      let collect = new Set();
      const balls = map(initial.numbers, "ball");
      // 合尾数
      if (initial.heweishu != "") {
        // console.log("initial.heweishu", initial.heweishu);
        let weishuSet = new Set();
        balls.forEach((item) => {
          let sum = item.split("").reduce(multiplyAddResult);
          let last = sum.toString().charAt(sum.toString().length - 1);
          if (initial.heweishu.toString().split("").indexOf(last) != -1) {
            weishuSet.add(item);
          }
        });
        collect.add(weishuSet);
      }
      // 和数值
      if (initial.heshuzhi != "") {
        // console.log("initial.heshuzhi", initial.heshuzhi);
        let heshuzhiSet = new Set();
        balls.forEach((item) => {
          let sum = item.split("").reduce(multiplyAddResult);
          if (initial.heshuzhi == sum.toString()) {
            heshuzhiSet.add(item);
          }
        });
        collect.add(heshuzhiSet);
      }
      // 胆码
      if (initial.danma != "") {
        // console.log("initial.danma", initial.danma);
        let danmaSet = new Set();
        balls.forEach((item) => {
          initial.danma
            .toString()
            .split("")
            .forEach((elment) => {
              if (item.toString().indexOf(elment) >= 0) {
                danmaSet.add(item);
              }
            });
        });
        collect.add(danmaSet);
      }
      // 勾选快捷01
      const filter01 = filter(initial.chk01, (elment) => {
        return elment.checked == true;
      });
      if (filter01 && filter01.length > 0) {
        filter01.forEach((v) => {
          switch (v.text) {
            case "合单":
            case "单点号码":
              let hedanSet = new Set();
              balls.forEach((item) => {
                let sum = item.split("").reduce(multiplyAddResult);
                if (Number(sum) % 2 != 0) {
                  hedanSet.add(item);
                }
              });
              collect.add(hedanSet);
              break;
            case "合双":
            case "双点号码":
              let heshuangSet = new Set();
              balls.forEach((item) => {
                let sum = item.split("").reduce(multiplyAddResult);
                if (Number(sum) % 2 == 0) {
                  heshuangSet.add(item);
                }
              });
              collect.add(heshuangSet);
              break;
            case "合大":
              let hedaSet = new Set();
              balls.forEach((item) => {
                let sum = item.split("").reduce(multiplyAddResult);
                let last = sum.toString().charAt(sum.toString().length - 1);
                if (Number(last) > 4) {
                  hedaSet.add(item);
                }
              });
              collect.add(hedaSet);
              break;
            case "合小":
              let hexiaoSet = new Set();
              balls.forEach((item) => {
                let sum = item.split("").reduce(multiplyAddResult);
                let last = sum.toString().charAt(sum.toString().length - 1);
                if (Number(last) < 5) {
                  hexiaoSet.add(item);
                }
              });
              collect.add(hexiaoSet);
              break;
            case "和值大":
              let hezhidaSet = new Set();
              balls.forEach((item) => {
                let sum = item.split("").reduce(multiplyAddResult);
                if (sum > 13) {
                  hezhidaSet.add(item);
                }
              });
              collect.add(hezhidaSet);
              break;
            case "和值小":
              let hezhixiaoSet = new Set();
              balls.forEach((item) => {
                let sum = item.split("").reduce(multiplyAddResult);
                if (sum < 14) {
                  hezhixiaoSet.add(item);
                }
              });
              collect.add(hezhixiaoSet);
              break;
            case "全奇":
              let quanjiSet = new Set();
              balls.forEach((item) => {
                if (
                  filter(item.split(""), (elment) => {
                    return Number(elment) % 2 != 0;
                  }).length == 3
                ) {
                  quanjiSet.add(item);
                }
              });
              collect.add(quanjiSet);
              break;
            case "全偶":
              let quanouSet = new Set();
              balls.forEach((item) => {
                if (
                  filter(item.split(""), (elment) => {
                    return Number(elment) % 2 == 0;
                  }).length == 3
                ) {
                  quanouSet.add(item);
                }
              });
              collect.add(quanouSet);
              break;
            case "两奇一偶":
              let liangjiyiouSet = new Set();
              balls.forEach((item) => {
                if (
                  filter(item.split(""), (elment) => {
                    return Number(elment) % 2 != 0;
                  }).length == 2
                ) {
                  liangjiyiouSet.add(item);
                }
              });
              collect.add(liangjiyiouSet);
              break;
            case "两偶一奇":
              let liangouyijiSet = new Set();
              balls.forEach((item) => {
                if (
                  filter(item.split(""), (elment) => {
                    return Number(elment) % 2 == 0;
                  }).length == 2
                ) {
                  liangouyijiSet.add(item);
                }
              });
              collect.add(liangouyijiSet);
              break;
            case "全大":
              let quandaSet = new Set();
              balls.forEach((item) => {
                if (
                  filter(item.split(""), (elment) => {
                    return Number(elment) > 4;
                  }).length == 3
                ) {
                  quandaSet.add(item);
                }
              });
              collect.add(quandaSet);
              break;
            case "全小":
              let quanxiaoSet = new Set();
              balls.forEach((item) => {
                if (
                  filter(item.split(""), (elment) => {
                    return Number(elment) < 5;
                  }).length == 3
                ) {
                  quanxiaoSet.add(item);
                }
              });
              collect.add(quanxiaoSet);
              break;
            case "两大一小":
              let liangdayixiaoSet = new Set();
              balls.forEach((item) => {
                if (
                  filter(item.split(""), (elment) => {
                    return Number(elment) > 4;
                  }).length == 2
                ) {
                  liangdayixiaoSet.add(item);
                }
              });
              collect.add(liangdayixiaoSet);
              break;
            case "两小一大":
              let liangxiaoyidaSet = new Set();
              balls.forEach((item) => {
                if (
                  filter(item.split(""), (elment) => {
                    return Number(elment) < 5;
                  }).length == 2
                ) {
                  liangxiaoyidaSet.add(item);
                }
              });
              collect.add(liangxiaoyidaSet);
              break;
            case "含质数号码":
              let zhishuSet = new Set();
              balls.forEach((item) => {
                if (
                  filter(item.split(""), (elment) => {
                    return initial.zhishu.indexOf(Number(elment)) >= 0;
                  }).length == 3
                ) {
                  zhishuSet.add(item);
                }
              });
              collect.add(zhishuSet);
              break;
            case "不含质数号码":
              let unzhishuSet = new Set();
              balls.forEach((item) => {
                if (
                  filter(item.split(""), (elment) => {
                    return initial.zhishu.indexOf(Number(elment)) < 0;
                  }).length == 3
                ) {
                  unzhishuSet.add(item);
                }
              });
              collect.add(unzhishuSet);
              break;
            case "三连号":
              let sanlianhaoSet = new Set();
              balls.forEach((item) => {
                let srResult = item
                  .split("")
                  .sort((a, b) => Number(a) - Number(b))
                  .map((el) => Number(el));
                if (
                  isContinuation(srResult) ||
                  srResult.join("") == "019" ||
                  srResult.join("") == "089"
                ) {
                  sanlianhaoSet.add(item);
                }
              });
              collect.add(sanlianhaoSet);
              break;
            case "半连号":
              let banlianhaoSet = new Set();
              balls.forEach((item) => {
                let srResult = item
                  .split("")
                  .sort((a, b) => Number(a) - Number(b))
                  .map((el) => Number(el));
                if (
                  srResult.join("") != "019" &&
                  srResult.join("") != "089" &&
                  srResult[0] == 0 &&
                  srResult[2] == 9
                ) {
                  banlianhaoSet.add(item);
                } else if (
                  srResult[0] + 1 == srResult[1] ||
                  srResult[1] + 1 == srResult[2]
                ) {
                  if (
                    srResult.join("") != "019" &&
                    srResult.join("") != "089" &&
                    !isContinuation(srResult)
                  ) {
                    banlianhaoSet.add(item);
                  }
                }
              });
              collect.add(banlianhaoSet);
              break;
            case "组三":
              let zusanSet = new Set();
              balls.forEach((item) => {
                let cbresult = [];
                combination(item.split(""), 0, 2, 0, [], cbresult);
                let cbSet = new Set();
                cbresult.forEach((elment) => {
                  cbSet.add(elment.join(""));
                });
                if (cbSet.size == 2) {
                  zusanSet.add(item);
                }
              });
              collect.add(zusanSet);
              break;
            case "不连号":
              let bulianhaoSet = new Set();
              balls.forEach((item) => {
                let srResult = item
                  .split("")
                  .sort((a, b) => Number(a) - Number(b))
                  .map((el) => Number(el));
                if (
                  srResult[0] + 1 != srResult[1] &&
                  srResult[1] + 1 != srResult[2] &&
                  (srResult[0] != 0 || srResult[2] != 9)
                ) {
                  if (!isContinuation(srResult)) {
                    bulianhaoSet.add(item);
                  }
                }
              });
              collect.add(bulianhaoSet);
              break;
            case "组六":
              let zuliuSet = new Set();
              balls.forEach((item) => {
                let cbresult = [];
                combination(item.split(""), 0, 2, 0, [], cbresult);
                let cbSet = new Set();
                cbresult.forEach((elment) => {
                  cbSet.add(elment.join(""));
                });
                if (cbSet.size == 3) {
                  zuliuSet.add(item);
                }
              });
              collect.add(zuliuSet);
              break;
          }
        });
      }
      // 勾选快捷02 二次和尾
      const filter02 = filter(initial.chk02, (elment) => {
        return elment.checked == true;
      });
      if (filter02 && filter02.length > 0) {
        filter02.forEach((v) => {
          let erciheweiSet = new Set();
          balls.forEach((item) => {
            let cbresult = [],
              total = 0;
            combination(item.split(""), 0, 2, 0, [], cbresult);
            // console.log("cbresult", cbresult);
            cbresult.forEach((elment) => {
              total += elment.reduce(multiplyAddResult);
            });
            if (endsWith(total.toString(), v.text)) {
              erciheweiSet.add(item);
            }
          });
          collect.add(erciheweiSet);
        });
      }
      // 勾选快捷03 二次差尾
      const filter03 = filter(initial.chk03, (elment) => {
        return elment.checked == true;
      });
      if (filter03 && filter03.length > 0) {
        filter03.forEach((v) => {
          let ercichaweiSet = new Set();
          balls.forEach((item) => {
            let cbresult = [],
              total = 0;
            combination(item.split(""), 0, 2, 0, [], cbresult);
            // console.log("cbresult", cbresult);
            cbresult.forEach((elment) => {
              total += Math.abs(elment.reduce(multiplySubtractResult));
            });
            if (endsWith(total.toString(), v.text)) {
              ercichaweiSet.add(item);
            }
          });
          collect.add(ercichaweiSet);
        });
      }
      // console.log("collect", collect);
      if (collect.size > 0) {
        let collectArray = [];
        collect.forEach((v, k) => {
          collectArray.push([...v]);
        });
        let intersectArray = collectArray.reduce((prev, cur) => {
          return Array.from(new Set(cur.filter((item) => prev.includes(item))));
        });
        // console.log("intersectArray", intersectArray);
        if (intersectArray.length > 0) {
          intersectArray.forEach((v) => {
            initial.result.push({ num: v, checked: true });
          });
        }
      }
      loading.value = false;
    };
    //加法运算
    const multiplyAddResult = (result, num) => {
      return Number(result) + Number(num);
    };
    //减法运算
    const multiplySubtractResult = (result, num) => {
      return Number(result) - Number(num);
    };
    /**
     * 清除选择
     */
    const emitClearBetEvent = () => {
      initial.result = [];
      initial.amount = null;
      initial.heweishu = "";
      initial.heshuzhi = "";
      initial.danma = "";
      initial.chk01.forEach((item) => {
        item.checked = false;
      });
      initial.chk02.forEach((item) => {
        item.checked = false;
      });
      initial.chk03.forEach((item) => {
        item.checked = false;
      });
    };

    //获取游戏賠率及限制
    const requestGameOddsAndFilterEvent = () => {
      let params = {
        plat: store.state.app.currentGame.plat,
        gameCode: store.state.app.currentGame.code,
        betType: initial.betType,
      };
      loading.value = true;
      store
        .dispatch("game/requestGameOddAndFilterAction", params)
        .then((res) => {
          loading.value = false;
          if (res.code == 0) {
            initial.numbers = res.res.numbers;
            initial.filter = res.res.filter;
          }
        })
        .then(() => {
          emitClearBetEvent();
        })
        .catch(() => {
          loading.value = false;
        });
    };

    /***
     * 快捷区域
     */

    //合尾数
    const heweishuChangeEvent = (e) => {
      switch (e.target.value) {
        case "单":
          initial.heweishu = filter(initial.number, (elment) => {
            return elment % 2 != 0;
          }).join("");
          break;
        case "双":
          initial.heweishu = filter(initial.number, (elment) => {
            return elment % 2 == 0;
          }).join("");
          break;
        case "大":
          initial.heweishu = filter(initial.number, (elment) => {
            return elment > 4;
          }).join("");
          break;
        case "小":
          initial.heweishu = filter(initial.number, (elment) => {
            return elment < 5;
          }).join("");
          break;
      }
    };
    const emitConfirmSuccessEvent = (data) => {
      emitClearBetEvent();
    };
    const emitConfirmCancelEvent = () => {
      postBet.contents = [];
    };

    return {
      store,
      filter,
      initial,
      loading,
      confirmBet,
      emitConfirmBetEvent,
      emitClearBetEvent,
      generateResultEvent,
      emitClearBetEvent,
      requestGameOddsAndFilterEvent,
      //快捷区域方法
      heweishuChangeEvent,
      emitConfirmSuccessEvent,
      emitConfirmCancelEvent,
      // checkQuick01TextEvent,
    };
  },
};
</script>

<style scoped lang="less">
:deep(.xtx-carousel) .carousel-btn.prev {
  left: 5px;
}

:deep(.xtx-carousel) .carousel-btn.next {
  right: 5px;
}
</style>
